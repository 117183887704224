import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, FlexList, FlexItem } from '../elements'
import RequestQuote from '../components/RequestQuote'

const RefrigeratedTruckloadPage = ({ data }) => {
  const postNode = {
    title: `Refrigerated Truckload - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Refrigerated Truckload - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="refrigerated-truckload" customTitle />

      <ServiceContainer>
        <PageTitle>Refrigerated Truckload</PageTitle>
        <LargeP>
          For over 35 years, D&D Transportation Services has been delivering
          temperature-controlled freight to customers throughout the United
          States.
        </LargeP>
        <p>
          A specialty since 1972, our team is seasoned in the refrigerated
          transport of goods. Through innovative leadership, we are able to
          offer state-of-the-art technology in equipment as well as process.
          Whether you need a long-haul or a short-haul, frozen, fresh or
          otherwise, D&D can deliver. Our expertise and knowledge of the
          industry, logistics capabilities and vehicle capacity are second to
          none. But, when it comes down to it, we are most proud of the
          personalize service and dedication that our people embrace and pass
          along in order to make your business and ours a success.
        </p>
        <RequestQuote />
        <h4>Refrigerated Truckload Benefits</h4>
        <FlexList>
          <FlexItem>Dependable On-time Service</FlexItem>
          <FlexItem>SmartWay Transport Partner</FlexItem>
          <FlexItem>Competitive Priced Transportation</FlexItem>
          <FlexItem>Year Round Dedicated Lanes</FlexItem>
          <FlexItem>Financial Strength</FlexItem>
          <FlexItem>Flexible Capacity</FlexItem>
          <FlexItem>Asset and Non-Asset Based</FlexItem>
          <FlexItem>Dedicated & Friendly Customer Service </FlexItem>
          <FlexItem>On Call 24/7 Service</FlexItem>
          <FlexItem>Award Winning Safety</FlexItem>
          <FlexItem>Trailer Drop and Spot Availability</FlexItem>
          <FlexItem>EDI Capability</FlexItem>
          <FlexItem>Online Load Posting</FlexItem>
          <FlexItem>Online Document Retrieval</FlexItem>
          <FlexItem>Electronic Wire Funds Transfer</FlexItem>
          <FlexItem>Customer Reporting</FlexItem>
          <FlexItem>Online Load Tracking</FlexItem>
          <FlexItem>Qualcomm Communication</FlexItem>
          <FlexItem>Electronic Logging</FlexItem>
          <FlexItem>In-Cab Scanning</FlexItem>
          <FlexItem>Route Optimization</FlexItem>
          <FlexItem>Modern & Fuel Efficient Equipment</FlexItem>
          <FlexItem>Full Service Maintenance Facility</FlexItem>
          <FlexItem>California Air Resources Board (CARB) Fleet</FlexItem>
          <FlexItem>2017 & Newer Tractors & Trailers</FlexItem>
          <FlexItem>53’ 102 Air Ride Trailers</FlexItem>
          <FlexItem>Haul up to 45,000 pounds</FlexItem>
          <FlexItem>State of the Art Temperature Controllers</FlexItem>
          <FlexItem>Trip Recorders on All Units</FlexItem>
          <FlexItem>State-of-the-Art Trailer Tracking</FlexItem>
        </FlexList>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default RefrigeratedTruckloadPage
